import React from "react";
import { Switch, Route, HashRouter } from "react-router-dom";
// import { } from "../Components/Pages";

const AppRouter = () => (
  <HashRouter>
    <>
      <Switch>
        <Route
          path="/"
          component={() => {
            window.location.href = "https://www.rapptrlabs.com/";
            return null;
          }}
        />
      </Switch>
    </>
  </HashRouter>
);

export default AppRouter;
